import React from "react";
import { Job } from "./Model/Job";
import { extractPrice, Scrap } from "./Model/Scrap";

interface PriceHistoryChartProps {
  job: Job;
  scraps: Scrap[];
}
export function PriceHistoryChart({
  job,
  scraps,
}: PriceHistoryChartProps): JSX.Element {
  const c: any = {
    type: "line",
    data: {
      datasets: [
        {
          label: "Price",
          data: scraps.map((s) => ({
            x: s.timestamp,
            y: extractPrice(s, job),
          })),
        },
      ],
    },
    options: {
      scales: {
        xAxes: [{ type: "time" }],
      },
    },
  };
  const url: string = `https://quickchart.io/chart?c=${JSON.stringify(c)}`;
  return <img style={{ width: "100%" }} src={url} alt="" />;
}

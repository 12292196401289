import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Box,
  TableContainer,
  Button,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import React, { useState } from "react";
import { Job } from "../Model/Job";
import { extractImage, Scrap } from "../Model/Scrap";
import { JobListPrice } from "./JobListPrice";
import { JobListLastChange } from "./JobListLastChange";

interface JobListProps {
  jobs: Job[];
  scrapsPerJob: Map<string, Scrap[]>;
}
export function JobList({ jobs, scrapsPerJob }: JobListProps): JSX.Element {
  const [showOnlyEnabled, setShowOnlyEnabled] = useState<boolean>(true);

  function renderJob(j: Job): JSX.Element {
    const scraps: Scrap[] = scrapsPerJob.get(j._id.$oid) ?? [];

    const latestScrap: Scrap | undefined = scraps[scraps.length - 1];
    let imageUrl: string | null = null;
    if (latestScrap) {
      imageUrl = extractImage(latestScrap);
    }

    return (
      <TableRow key={j._id.$oid}>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Stack direction="column">
              <Box component="span">
                {j.name} {!j.isEnabled && `(disabled)`}
              </Box>
              <Box component="span" color="text.secondary">
                {new URL(j.url).hostname}
              </Box>
            </Stack>
            {imageUrl && (
              <img src={imageUrl} alt="" style={{ height: "40px" }} />
            )}
          </Stack>
        </TableCell>
        <TableCell align="right">
          <JobListPrice job={j} scraps={scraps} />
        </TableCell>
        <TableCell>
          <JobListLastChange scraps={scraps} />
        </TableCell>
        <TableCell>
          <IconButton href={`#/job/${j._id.$oid}`}>
            <ChevronRight />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  if (jobs.length === 0) {
    return <p>The list is empty.</p>;
  }

  return (
    <Stack sx={{ m: 1 }} spacing={2}>
      <TableContainer>
        <Table>
          <TableBody>
            {jobs.filter((j) => !showOnlyEnabled || j.isEnabled).map(renderJob)}
          </TableBody>
        </Table>
      </TableContainer>
      {showOnlyEnabled && (
        <Button variant="outlined" onClick={() => setShowOnlyEnabled(false)}>
          Show disabled jobs
        </Button>
      )}
    </Stack>
  );
}

import {
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Job } from "../Model/Job";
import { extractPrice, Scrap } from "../Model/Scrap";
import { getRelativeTime } from "../getRelativeTime";
import { PriceHistoryChart } from "../PriceHistoryChart";
import { ChevronLeft } from "@mui/icons-material";
import { StorageContext } from "../Storage/StorageContext";
import { useExistingContext } from "../Hooks/useExistingContext";
import { IStorageProvider } from "../Storage/IStorageProvider";
import { JobIsEnabledSwitch } from "../Settings/JobIsEnabledSwitch";

export function JobHistoryPage(): JSX.Element {
  const { currentJobId } = useParams();
  const storage: IStorageProvider = useExistingContext(StorageContext);
  const [job, setJob] = useState<Job | null>(null);
  const [scraps, setScraps] = useState<Scrap[]>([]);

  useEffect(() => {
    if (typeof currentJobId === "undefined") {
      return;
    }
    void storage.getJob(currentJobId).then((job: Job | null) => {
      setJob(job);
    });
    void storage.getScrapsForJob(currentJobId).then((scraps: Scrap[]) => {
      setScraps(scraps);
    });
  }, [storage, currentJobId]);

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Stack direction="row" sx={{ m: 1 }} spacing={2}>
            <Link href="#" sx={{ ml: 2 }}>
              <IconButton>
                <ChevronLeft />
              </IconButton>
            </Link>
            {job && (
              <Stack direction="column">
                <Typography>{job.name}</Typography>
                <Link href={job.url}>{job.url}</Link>
              </Stack>
            )}
            {job && <JobIsEnabledSwitch job={job} />}
          </Stack>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={8}>
            {job && <PriceHistoryChart job={job} scraps={scraps} />}
          </Grid>
          <Grid item xs={4}>
            {job && (
              <TableContainer>
                <Table>
                  <TableBody>
                    {scraps
                      .slice()
                      .reverse()
                      .map((s) => (
                        <TableRow key={s._id.$oid}>
                          <TableCell>{getRelativeTime(s.timestamp)}</TableCell>
                          <TableCell>{extractPrice(s, job)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import { Box } from "@mui/material";
import { getRelativeTime } from "../getRelativeTime";
import { Scrap } from "../Model/Scrap";

interface JobListLastChangeProps {
  scraps: Scrap[];
}
export function JobListLastChange({
  scraps,
}: JobListLastChangeProps): JSX.Element | null {
  const lastScrap: Scrap | undefined = scraps[scraps.length - 1];
  if (!lastScrap) {
    return null;
  }
  const text: string = getRelativeTime(lastScrap.timestamp);
  return <Box component={"span"}>{text}</Box>;
}

import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  MessagePayload,
  Messaging,
  NextFn,
  Observer,
  onMessage,
} from "firebase/messaging";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyAwSEmzgIIpCYTdkxLMG1K0aptuxsvyCW4",
  authDomain: "monitor-9b200.firebaseapp.com",
  projectId: "monitor-9b200",
  storageBucket: "monitor-9b200.appspot.com",
  messagingSenderId: "1012768452606",
  appId: "1:1012768452606:web:cfc5ea557e391b45bb1aaf",
  measurementId: "G-JGK0Y1WDQT",
};
const vapidKey: string =
  "BA3RxfO5AZomS6fsLXj_776gg4Uy4Rjy7xAfsmb1Y4me1OlxyTKlAMmzmDMtcmEj12Pfc7N2LnQsVDncU_XZyh0";
const app: FirebaseApp = initializeApp(firebaseConfig);
const messaging: Messaging = getMessaging();

const firebase: {
  app: FirebaseApp;
  getMessagingToken: () => Promise<string>;
  onMessage: (
    callback: NextFn<MessagePayload> | Observer<MessagePayload>
  ) => void;
} = {
  app,
  getMessagingToken: async () => {
    return getToken(messaging, {
      vapidKey,
    });
  },
  onMessage: (callback: NextFn<MessagePayload> | Observer<MessagePayload>) => {
    onMessage(messaging, callback);
  },
};
export default firebase;

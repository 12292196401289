import React from "react";
import { Box, Stack } from "@mui/material";
import { Job } from "../Model/Job";
import { Scrap, extractPrice } from "../Model/Scrap";
interface JobListPriceProps {
  job: Job;
  scraps: Scrap[];
}
export function JobListPrice({
  job,
  scraps,
}: JobListPriceProps): JSX.Element | null {
  const last: Scrap | undefined = scraps[scraps.length - 1];
  const lastPrice: number | null = last ? extractPrice(last, job) : null;
  if (lastPrice === null) {
    return null;
  }
  const lastButOne: Scrap | undefined = scraps[scraps.length - 2];
  const lastButOnePrice: number | null = lastButOne
    ? extractPrice(lastButOne, job)
    : null;
  if (lastButOnePrice === null) {
    return <Box component="span">{lastPrice.toFixed(2)}</Box>;
  }

  const diff: number = lastPrice - lastButOnePrice;
  if (diff === 0) {
    return <Box component="span">{lastPrice.toFixed(2)}</Box>;
  }
  const diffSign: string = diff > 0 ? "+" : "-";
  const diffColor: string = diff > 0 ? "error.main" : "success.main";
  return (
    <Stack direction="column">
      <Box component="span">{lastPrice.toFixed(2)}</Box>
      <Box component="span" sx={{ color: diffColor }}>
        {diffSign}
        {Math.abs(diff).toFixed(2)}
      </Box>
    </Stack>
  );
}

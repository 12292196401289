import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import firebase from "../FirebaseApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const vapidTokenKey: string = "vapidToken";
export function NotificationSwitch(): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    const token: string | null = localStorage.getItem(vapidTokenKey);
    if (token === null) {
      setIsChecked(false);
      setIsPending(false);
    } else {
      void requestIsSubscribed(token).then((isSubscribed) => {
        setIsPending(false);
        setIsChecked(isSubscribed);
      });
    }
  }, []);

  async function requestIsSubscribed(token: string): Promise<boolean> {
    return post(
      "https://data.mongodb-api.com/app/crawler-thpmc/endpoint/isSubscribed",
      token
    ).then((r) => !!r.isSubscribed);
  }

  async function requestSubscribe(token: string): Promise<void> {
    return post(
      "https://data.mongodb-api.com/app/crawler-thpmc/endpoint/subscribe",
      token
    );
  }
  async function requestUnsubscribe(token: string): Promise<void> {
    return post(
      "https://data.mongodb-api.com/app/crawler-thpmc/endpoint/unsubscribe",
      token
    );
  }

  async function onChange(newState: boolean): Promise<void> {
    setIsPending(true);
    setIsChecked(newState);
    let token: string | null = localStorage.getItem(vapidTokenKey);
    if (token === null && newState) {
      token = await firebase.getMessagingToken();
      localStorage.setItem(vapidTokenKey, token);
    }
    if (newState && token !== null) {
      await requestSubscribe(token);
    } else {
      if (token !== null) {
        await requestUnsubscribe(token);
      }
      localStorage.removeItem(vapidTokenKey);
    }
    setIsPending(false);
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            color="default"
            checked={isChecked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={isPending}
          />
        }
        label={isChecked ? <NotificationsActiveIcon /> : <NotificationsIcon />}
      />
    </FormGroup>
  );
}

async function post(url: string, token: string): Promise<any> {
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  })
    .then((response) => response.text())
    .then((text) => JSON.parse(text));
}

import { Id } from "./Id";
import { Job } from "./Job";

export interface Scrap {
  _id: Id;
  job_id: Id;
  timestamp: number;
}

export interface HtmlScrap extends Scrap {
  html: string;
  hash: string;
  length: number;
  isFalseAlarm?: boolean;
}

export interface MetaScrap extends Scrap {
  json: string;
}

export function isHtmlScrap(scrap: Scrap): scrap is HtmlScrap {
  return typeof (scrap as HtmlScrap).html !== `undefined`;
}

export function isMetaScrap(scrap: Scrap): scrap is MetaScrap {
  return typeof (scrap as MetaScrap).json !== `undefined`;
}

export function extractImage(scrap: Scrap): string | null {
  if (!isMetaScrap(scrap)) {
    return null;
  }
  const parsed: any = JSON.parse(scrap.json);
  return parsed.image ?? null;
}

export function extractPrice(scrap: Scrap, job: Job): number | null {
  if (isMetaScrap(scrap)) {
    const parsed: any = JSON.parse(scrap.json);
    return (parsed.price as number) ?? null;
  } else if (isHtmlScrap(scrap)) {
    if (typeof job.valueMatcher !== `string`) {
      return null;
    } else {
      const div: HTMLDivElement = document.createElement("div");
      div.innerHTML = scrap.html;
      const matchResult: RegExpMatchArray | null =
        div.textContent?.replaceAll(/\n|\s/g, "").match(job.valueMatcher) ??
        null;
      if (matchResult === null) {
        return null;
      }
      return parseFloat(matchResult.slice(1).join(""));
    }
  } else {
    return null;
  }
}

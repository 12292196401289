import { Job } from "../Model/Job";
import { Scrap } from "../Model/Scrap";

export interface HomePageState {
  isInitialized: boolean;
  query: string;
  jobs: Job[];
  scrapsPerJobs: Map<string, Scrap[]>;
}

interface HomePageReducerActionBase {
  type: string;
}

type HomePageReducerAction =
  | HomePageQueryCompletedReducerAction
  | HomePageQueryJobsReducerAction;

interface HomePageQueryCompletedReducerAction
  extends HomePageReducerActionBase {
  type: `queryCompleted`;
  jobs: Job[];
  scrapsPerJob: Map<string, Scrap[]>;
}

interface HomePageQueryJobsReducerAction extends HomePageReducerActionBase {
  type: `queryJobs`;
  query: string;
}

export const homePageReducer: React.Reducer<
  HomePageState,
  HomePageReducerAction
> = (state: HomePageState, action: HomePageReducerAction) => {
  switch (action.type) {
    case `queryCompleted`:
      return {
        ...state,
        isInitialized: true,
        jobs: action.jobs,
        scrapsPerJobs: action.scrapsPerJob,
      };
    case `queryJobs`:
      return {
        ...state,
        query: action.query,
      };
  }
};

import { ChevronLeft } from "@mui/icons-material";
import { Button, IconButton, Link, MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useExistingContext } from "../Hooks/useExistingContext";
import { Job, JobDraft } from "../Model/Job";
import { IStorageProvider } from "../Storage/IStorageProvider";
import { StorageContext } from "../Storage/StorageContext";
import { NavigateFunction, useNavigate } from "react-router-dom";
function getJobDraft(): JobDraft {
  return {
    elementType: "metascraper",
    name: "",
    url: "",
  };
}
export function AddJobPage(): JSX.Element {
  const storage: IStorageProvider = useExistingContext(StorageContext);
  const navigate: NavigateFunction = useNavigate();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [jobDraft, setJobDraft] = useState<JobDraft>(getJobDraft());
  async function handleAddClick(): Promise<void> {
    if (jobDraft.url && jobDraft.name) {
      setIsPending(true);
      const job: Job = await storage.addJob(jobDraft);
      setIsPending(false);
      navigate(`/job/${job._id.$oid}`);
    }
  }

  return (
    <>
      <Link href="#" sx={{ ml: 2 }}>
        <IconButton>
          <ChevronLeft />
        </IconButton>
      </Link>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          label="Name"
          value={jobDraft.name}
          onChange={(e) => setJobDraft({ ...jobDraft, name: e.target.value })}
          required
          disabled={isPending}
          margin="dense"
        />
        <TextField
          label="URL"
          type="url"
          value={jobDraft.url}
          onChange={(e) => setJobDraft({ ...jobDraft, url: e.target.value })}
          required
          disabled={isPending}
          margin="dense"
        />
        <TextField
          label="elementType"
          disabled
          value={jobDraft.elementType}
          required
          margin="dense"
          select
        >
          <MenuItem key="metascraper" value="metascraper">
            metascraper
          </MenuItem>
        </TextField>
        <Button onClick={handleAddClick} disabled={isPending}>
          Add
        </Button>
      </Box>
    </>
  );
}

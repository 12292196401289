const rtf: Intl.RelativeTimeFormat = new Intl.RelativeTimeFormat("en", {
  style: "narrow",
  numeric: "auto",
});
export function getRelativeTime(time: number): string {
  let diff: number = time - Date.now();
  if (diff > -1000) {
    return "Just now";
  }
  diff = Math.floor(diff / 1000);
  if (diff > -60) {
    return rtf.format(diff, "seconds");
  }

  diff = Math.floor(diff / 60);
  if (diff > -60) {
    return rtf.format(diff, "minutes");
  }

  diff = Math.floor(diff / 60);
  if (diff > -24) {
    return rtf.format(diff, "hours");
  }

  diff = Math.floor(diff / 24);
  return rtf.format(diff, "days");
}

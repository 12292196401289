import { Job } from "./Job";
import { Scrap } from "./Scrap";

export function getJobsSorter(
  sortedScrapsPerJob: Map<string, Scrap[]>
): (a: Job, b: Job) => number {
  return (a: Job, b: Job) => {
    if (a.isEnabled !== b.isEnabled) {
      return a.isEnabled ? -1 : 1;
    }

    const aScraps: Scrap[] = sortedScrapsPerJob.get(a._id.$oid) ?? [];
    const bScraps: Scrap[] = sortedScrapsPerJob.get(b._id.$oid) ?? [];
    const newestAScrap: Scrap | undefined = aScraps[aScraps.length - 1];
    const newestBScrap: Scrap | undefined = bScraps[bScraps.length - 1];
    if (newestAScrap === newestBScrap) {
      return 0;
    } else if (typeof newestAScrap === `undefined`) {
      return 1;
    } else if (typeof newestBScrap === `undefined`) {
      return -1;
    } else {
      const diff: number = newestBScrap.timestamp - newestAScrap.timestamp;
      return diff;
    }
  };
}

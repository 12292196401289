import React, { useCallback, useEffect, useReducer } from "react";
import { Job } from "../Model/Job";
import { Scrap } from "../Model/Scrap";
import { JobList } from "../JobList/JobList";
import { JobFilter } from "../JobFilter/JobFilter";
import { getJobsSorter } from "../Model/jobsSorter";
import { StorageContext } from "../Storage/StorageContext";
import { IStorageProvider } from "../Storage/IStorageProvider";
import { useExistingContext } from "../Hooks/useExistingContext";
import { HomePageState, homePageReducer } from "./homePageReducer";
import { CircularProgress, Stack } from "@mui/material";

const initialState: HomePageState = {
  isInitialized: false,
  jobs: [],
  query: "",
  scrapsPerJobs: new Map(),
};

export function HomePage(): JSX.Element {
  console.log(`HomePage`);
  const [state, dispatch] = useReducer(homePageReducer, initialState);
  const storage: IStorageProvider = useExistingContext(StorageContext);

  const retrieveData: (splitQuery: string[]) => void = useCallback(
    async (splitQuery: string[]) => {
      const matchingJobs: Job[] = await storage.getJobsByQueries(splitQuery);
      const scrapsPerJob: Map<string, Scrap[]> = await storage.getScrapsForJobs(
        matchingJobs.map((j) => j._id.$oid)
      );
      const sortedJobs: Job[] = matchingJobs
        .slice()
        .sort(getJobsSorter(scrapsPerJob));
      dispatch({
        type: `queryCompleted`,
        jobs: sortedJobs,
        scrapsPerJob: scrapsPerJob,
      });
    },
    [storage]
  );

  const onQueryChange: (newQuery: string) => void = useCallback(
    (newQuery) => dispatch({ type: `queryJobs`, query: newQuery }),
    []
  );

  useEffect(() => {
    const splitQuery: string[] = state.query.split(" ");
    retrieveData(splitQuery);
  }, [state.query, retrieveData]);

  if (!state.isInitialized) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack spacing={2}>
      <JobFilter onQueryChange={onQueryChange} />
      <JobList jobs={state.jobs} scrapsPerJob={state.scrapsPerJobs} />
    </Stack>
  );
}

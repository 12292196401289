import { Job, JobDraft } from "../Model/Job";
import { Scrap } from "../Model/Scrap";
import { IStorageProvider } from "./IStorageProvider";

interface MongoStorageProviderCache {
  jobs: Job[];
  scraps: Scrap[];
}

export class MongoStorageProvider implements IStorageProvider {
  private cache: MongoStorageProviderCache | null = null;
  constructor(private dataEndpointBase: string) {}

  public async addJob(draft: JobDraft): Promise<Job> {
    const response: Response = await fetch(this.dataEndpointBase + "/addJob", {
      body: JSON.stringify({ draft }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const parsedResponse: any = await response.json();
    const job: Job = parsedResponse.job as Job;
    (await this.getCache()).jobs.push(job);
    return job;
  }

  public async disableJob(jobId: string): Promise<boolean> {
    const response: Response = await fetch(
      this.dataEndpointBase + "/disableJob",
      {
        body: JSON.stringify({ jobId }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const parsedResponse: any = await response.json();
    const newState: boolean = !!parsedResponse.isEnabled;
    const job: Job | undefined = (await this.getCache()).jobs.find(
      (j) => j._id.$oid === jobId
    );
    if (job) {
      job.isEnabled = newState;
    }
    return newState;
  }
  public async enableJob(jobId: string): Promise<boolean> {
    const response: Response = await fetch(
      this.dataEndpointBase + "/enableJob",
      {
        body: JSON.stringify({ jobId }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const parsedResponse: any = await response.json();
    const newState: boolean = !!parsedResponse.isEnabled;
    const job: Job | undefined = (await this.getCache()).jobs.find(
      (j) => j._id.$oid === jobId
    );
    if (job) {
      job.isEnabled = newState;
    }
    return newState;
  }

  public async getScrapsForJobs(
    jobIds: string[]
  ): Promise<Map<string, Scrap[]>> {
    const scraps: Scrap[] = await this.getScraps();
    const result: Map<string, Scrap[]> = new Map();
    scraps.forEach((scrap) => {
      if (!jobIds.includes(scrap.job_id.$oid)) {
        return;
      }
      const relatedScraps: Scrap[] = result.get(scrap.job_id.$oid) ?? [];
      relatedScraps.push(scrap);
      result.set(scrap.job_id.$oid, relatedScraps);
    });
    return result;
  }

  public async getScrapsForJob(jobId: string): Promise<Scrap[]> {
    const scraps: Scrap[] = await this.getScraps();
    return scraps.filter((s) => s.job_id.$oid === jobId);
  }

  public async getJob(jobId: string): Promise<Job | null> {
    const jobs: Job[] = await this.getJobs();
    return jobs.find((j) => j._id.$oid === jobId) ?? null;
  }

  public async getJobs(): Promise<Job[]> {
    return (await this.getCache()).jobs;
  }

  public async getJobsByQueries(query: string[]): Promise<Job[]> {
    const jobs: Job[] = await this.getJobs();
    const lowerCasedQuery: string[] = query.map((q) => q.toLowerCase());
    return jobs.filter((job) => {
      const lowerCasedName: string = job.name.toLowerCase();
      const lowerCasedUrl: string = job.url.toLowerCase();
      return lowerCasedQuery.some(
        (q) => lowerCasedName.includes(q) || lowerCasedUrl.includes(q)
      );
    });
  }

  public async getScraps(): Promise<Scrap[]> {
    return (await this.getCache()).scraps;
  }

  private async getCache(): Promise<MongoStorageProviderCache> {
    if (this.cache === null) {
      const response: Response = await fetch(this.dataEndpointBase + "/data");
      const json: any = await response.json();
      if (typeof json !== `object`) {
        throw new Error(`Response is not an object: ${typeof json}`);
      }
      this.cache = {
        jobs: json.jobs,
        scraps: json.metascraps.concat(json.html).map((s: any) => ({
          ...s,
          timestamp: parseInt(s.timestamp.$numberDouble, 10),
        })),
      };
    }
    return this.cache;
  }
}

import React, { MutableRefObject, useRef } from "react";
import "./App.css";
import { Container } from "@mui/material";
import { HashRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./Pages/HomePage";
import { JobHistoryPage } from "./Pages/JobHistoryPage";
import { StorageContext } from "./Storage/StorageContext";
import { MongoStorageProvider } from "./Storage/MongoStorageProvider";
import { IStorageProvider } from "./Storage/IStorageProvider";
import { TopAppBar } from "./TopAppBar";
import { AddJobPage } from "./Pages/AddJobPage";

function App() {
  console.log(`App`);
  const storageProvider: MutableRefObject<IStorageProvider> = useRef(
    new MongoStorageProvider(
      "https://data.mongodb-api.com/app/crawler-thpmc/endpoint"
    )
  );
  return (
    <StorageContext.Provider value={storageProvider.current}>
      <TopAppBar />
      <Container>
        <HashRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="job/:currentJobId" element={<JobHistoryPage />} />
            <Route path="addJob" element={<AddJobPage />} />
          </Routes>
        </HashRouter>
      </Container>
    </StorageContext.Provider>
  );
}

export default App;

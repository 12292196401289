import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";
import { useExistingContext } from "../Hooks/useExistingContext";
import { Job } from "../Model/Job";
import { IStorageProvider } from "../Storage/IStorageProvider";
import { StorageContext } from "../Storage/StorageContext";

interface JobIsEnabledSwitchProps {
  job: Job;
}
export function JobIsEnabledSwitch({
  job,
}: JobIsEnabledSwitchProps): JSX.Element {
  const storage: IStorageProvider = useExistingContext(StorageContext);
  const [isChecked, setIsChecked] = useState<boolean>(job.isEnabled);
  const [isPending, setIsPending] = useState<boolean>(false);

  async function onChange(newState: boolean): Promise<void> {
    setIsPending(true);
    setIsChecked(newState);
    let newConfirmedState: boolean = newState;
    if (newState) {
      newConfirmedState = await storage.enableJob(job._id.$oid);
    } else {
      newConfirmedState = await storage.disableJob(job._id.$oid);
    }
    setIsChecked(newConfirmedState);
    setIsPending(false);
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={isPending}
          />
        }
        label="Enabled"
      />
    </FormGroup>
  );
}

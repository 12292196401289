import { Box, AppBar, Toolbar, Typography, Button } from "@mui/material";
import React from "react";
import { NotificationSwitch } from "./Settings/NotificationSwitch";

export function TopAppBar(): JSX.Element {
  return (
    <Box sx={{ flexGrow: 1 }} mb={2}>
      <AppBar position="static">
        <Toolbar>
          {/*<IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>*/}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Monitor
          </Typography>
          <NotificationSwitch />
          <Button href="#/addJob" color="inherit">
            Add job
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

import { Close, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface JobFilterProps {
  onQueryChange: (newQuery: string) => void;
}
export function JobFilter({ onQueryChange }: JobFilterProps): JSX.Element {
  const [viewValue, setViewValue] = useState<string>("");
  const timeoutHandle: React.MutableRefObject<number | null> = useRef(null);

  useEffect(() => {
    if (timeoutHandle.current !== null) {
      window.clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      onQueryChange(viewValue);
    }, 300);
  }, [viewValue, onQueryChange]);
  return (
    <TextField
      fullWidth
      value={viewValue}
      autoFocus
      placeholder="Search names and urls"
      onChange={(e) => {
        setViewValue(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {viewValue.length > 0 && (
              <IconButton onClick={() => onQueryChange("")}>
                <Close />
              </IconButton>
            )}
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
